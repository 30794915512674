<template>
  <promises-summary :promisesData="promiseData" />
</template>

<script>

export default {
  components: {
    PromisesSummary: () => import('@/components/kpi/promises-summary.vue')
  },
  data () {
    return {
      promiseData: {}
    }
  },
  computed: {
  },
  methods: {
    async refreshData () {
      this.promiseData = {}
      this.promiseData = (await this.$http().versionHashed().get('/core/v6/dashboard/agent/promises/' + this.showAll)).data
    }
  },
  watch: {
    showAll: {
      handler (v) {
        if (typeof v !== 'undefined') {
          this.refreshData()
        }
      },
      immediate: true
    }
  },
  props: {
    showAll: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
